// React
import React, { useCallback, useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
// Componenets
import { Columns } from "../components/Columns/Columns";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import Loader from "../components/Loader";
import Table from "../components/Tables/RecentEvent";
// OpenAPI
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { getApiData } from "../controller/getApiDataConfiguration";
// Utils
import { formatAndValidateDate, getFileSize } from "../utils";
import { addBrokerClosingDateLocally } from "../components/EditableFields/EditableFieldsUtilities/editableFieldsUtils";
import { removeEmptyHTML } from "../utils";

// Types
import {
  Sort,
  Item,
  ItemMetaData,
  ResponseFile,
  HandleMenuProps,
} from "../interfaces/pages/variedInterfaces";
import { IConditions } from "../types/mna-types";



// Images
import close from "./../assets/svg/close.svg";
import EditableFieldMna from "../components/EditableFields/EditableFieldMna";
import { FileDomainApi, SectorDomainApi } from "../openapi";
import {apiConfig} from "../components/ConfigurationApi/Configuration";

const MnaDetails: React.FC<HandleMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const safeId = id!;
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState<string | undefined>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [sectorList, setSectorList] = useState<any>([]);
  const [fetchRequired, setFetchRequired] = useState(false);

  useEffect(() => {
    fetchSectorList();
  }, []);

  const fetchSectorList = async () => {
    try {
      const api = new getApiData();
      const response = await api.getSectorDomainApi(id);
      const filteredOptions = response?.data?.content!.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setSectorList(filteredOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMnaData = async () => {
    setLoading(true);
    try {
      const api = new getApiData();
      const response = await api.getMnaDomainApi2(id, fetchFeatures);
      // Check if sectorAcquirer is a number and not null or empty string
      if (
        !isNaN(Number(response.sectorAcquirer)) &&
        response.sectorAcquirer !== null &&
        response.sectorAcquirer !== ""
      ) {
        response.sectorAcquirer = await fetchSectorNameById(
          Number(response.sectorAcquirer)
        );
      }

      // Check if sectorTarget is a number and not null or empty string
      if (
        !isNaN(Number(response.sectorTarget)) &&
        response.sectorTarget !== null &&
        response.sectorTarget !== ""
      ) {
        response.sectorTarget = await fetchSectorNameById(
          Number(response.sectorTarget)
        );
      }
      setData(response);
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const tabsContent = [
    {
      header: "What Matters",
      content: (
        <>
          <div className=" px-3.5 pt-4 pb-2 bg-table-light dark:bg-table-dark text-title text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
            What Matters
          </div>
          <div className="bg-table-light dark:bg-table-dark rounded-bl-md rounded-br-md overflow-hidden">
            <ul className="list-disc pl-8 pt-2">
              {data?.whatMatters?.map(
                (item: Item, index: number) =>
                  item?.note && (
                    <li key={`what-matters-${index}`} className="text-sm">
                      <div
                        className="pb-2.5"
                        dangerouslySetInnerHTML={{
                          __html: removeEmptyHTML(item.note) || "",
                        }}
                      />
                    </li>
                  )
              )}
            </ul>
          </div>
        </>
      ),
    },
    {
      header: "Company Description",
      content: (
        <table  style={{ width: '100%' }}> 
          <caption className="text-left text-[18px] font-semibold  text-title pt-4 px-4">
            Company Description
          </caption>
                  <tbody className="">
            <tr>
              <td className="pl-4 pr-3.5  text-[#696868] dark:text-white   text-sm font-normal align-top ">
               <p className="mt-[8px]">Description:</p> 
              </td>
             
             
            </tr>
            <tr>
              <td>
              <div className="w-full px-4 ">
                <EditableFieldMna 
                 data={data?.description}
                 fieldtype="textEditor"
                 fieldName="description"
                 fetchMnaData={fetchMnaData}
                />
                </div>
                </td>
              </tr>
            <tr>
              <td className="pl-4 pr-3.5  text-[#696868] dark:text-white   text-sm font-normal align-top">
               <p className="mt-[8px]">Competitors:</p> 
              </td>
        
            </tr>
            <tr className="w-full">
              <td>
              <div className="w-full pl-2 pr-4 ">
                <EditableFieldMna 
                 data={data?.competitors}
                 fieldtype="text"
                 fieldName="competitors"
                 fetchMnaData={fetchMnaData}
                />
                </div>
                </td>
              </tr>
            <tr>
              <td className="pl-4 pr-3.5 py-2.5  text-[#696868] dark:text-white   text-sm font-normal align-top">
              <p className="">Customers:</p>  
              </td>
             
            </tr>
            <tr className="w-full">
              <td>
              <div className="w-full  pl-2 pr-4 ">
                <EditableFieldMna 
                 data={data?.customers}
                 fieldtype="text"
                 fieldName="customers"
                 fetchMnaData={fetchMnaData}
                />
                </div>
                </td>
              </tr>
          </tbody>
        </table>
      ),
    },
    {
      header: "Blomberg info",
      content: <></>,
    },
  ];
  useEffect(() => {
    fetchMnaData();
  }, [fetchRequired]);
  const fetchFeatures = async (): Promise<any[]> => {
    try {
      const api = new getApiData();
      return api.getFeatureDomainApi();
    } catch (error) {
      console.error("Error fetching options:", error);
      expiredTokenValidation(error);
      return [];
    }
  };

  const fetchSectorNameById = async (sectorId: number) => {
    try {
      const api = new SectorDomainApi(apiConfig());
      const response = await api.getSectorById(sectorId);
      return response.data?.name || "";
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  const columnsInstance = new Columns();

  const fetchMnaNoteList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      const getFetchMnaNoteList = new getApiData();
      return getFetchMnaNoteList.getNoteDomainApi2(
        String(id),
        pageIndex,
        pageSize,
        sortBy,
        "Mna"
      );
    },
    [id]
  );

  const openModal = (content: string) => {
    setContent(content);

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFileDownload = async (id: string, type: string) => {
    try {
      // Set Authorization header
      const headers: {} = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const api = new FileDomainApi(apiConfig());
      const response = await api.downloadFile(id);
      const imageUrl = (response as ResponseFile).config.url;

      // Getting the image url
      const imageResponse = await fetch(imageUrl, { headers });

      // If the request image response it's ok create Blob with the imageResponse
      if (imageResponse.ok) {
        const imageBlob = await imageResponse.blob();

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(imageBlob);

        // Config file name
        const parts = type.split(".");
        const fileName = parts[0];
        downloadLink.download = `${fileName}.${parts[1] || "png"}`;

        // Add url at document body and simulate click to start the donwload
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return;
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <React.Fragment>
      <div
        className={`py-[24px] pr-[24px] ${
          isMenuOpen ? "pl-[308px]" : "pl-[84px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["MNA", `${data?.name || ""}`]}
          icon={""}
          updateItem={() => navigate(`/update-mna/${id}`)}
        />
        {/* COMPANY INFORMATION */}
        <div className="  dark:bg-table-dark rounded-md mt-[24px]">
          <div className="flex flex-col md:flex-row gap-[16px] mb-[16px]">
            <div className="md:w-1/2 flex flex-col gap-[16px]">
              <div>
                <div className=" min-w-full inline-block align-middle ">
                  <div className="border border-[#DCDBEE] rounded-md overflow-visible bg-table-light dark:bg-table-dark pb-[8px] pr-2">
                    <table className="min-w-full  dark:bg-table-dark bg-table-light ">
                      <caption className="text-left text-[18px] bg-table-light  dark:bg-table-dark font-semibold text-title pt-4 pb-2 px-4 ">
                        Company Information
                      </caption>
                      <thead className="bg-table-light dark:bg-table-dark dark:text-white">
                        <tr></tr>
                      </thead>
                      <tbody className="divide-[#DCDBEE] dark:bg-table-dark dark:text-white ">
                        <tr>
                          <td className="px-3.5 pb-2.5 pt-2  text-[#696868] dark:text-white   text-sm font-norma w-[25%] h-full">
                            Ticker:
                          </td>
                          <td className="w-[37.5%] h-full ">
                            <p className="pl-2"> {data?.tickerTarget}</p>
                          </td>
                          <td className="w-[37.5%] h-full">
                            <p className="pl-2"> {data?.tickerAcquirer}</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 text-[#696868] dark:text-white   text-sm font-normal w-[25%] h-full">
                            Name:
                          </td>
                          <td className="w-[37.5%] h-full "> </td>
                          <td className="w-[37.5%] h-full ">
                            <EditableFieldMna
                              data={data?.name}
                              fieldtype="text"
                              fieldName="name"
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white   text-sm font-normal w-[25%] h-full">
                            Sector:
                          </td>
                          <td className="w-[37.5%] h-full">
                            <EditableFieldMna
                              data={data?.sectorTarget}
                              fieldtype="select"
                              fieldName="sectorTarget"
                              sectorList={sectorList}
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                          <td className="w-[37.5%] h-full">
                            <EditableFieldMna
                              data={data?.sectorAcquirer}
                              fieldtype="select"
                              fieldName="sectorAcquirer"
                              sectorList={sectorList}
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white   text-sm font-normal w-[25%] h-full">
                            Incorp:
                          </td>
                          <td className="w-[37.5%] h-full">
                            <EditableFieldMna
                              data={data?.incorpTarget}
                              fieldtype="text"
                              fieldName="incorpTarget"
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                          <td className="w-[37.5%] h-full">
                            <EditableFieldMna
                              data={data?.incorpAcquirer}
                              fieldtype="text"
                              fieldName="incorpAcquirer"
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white   text-sm font-normal w-[25%] h-full">
                            HQ:
                          </td>
                          <td className="w-[37.5%] h-full">
                            <EditableFieldMna
                              data={data?.hqTarget}
                              fieldtype="text"
                              fieldName="hqTarget"
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                          <td className="w-[37.5%] h-full">
                            <EditableFieldMna
                              data={data?.hqAcquirer}
                              fieldtype="text"
                              fieldName="hqAcquirer"
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white   text-sm font-normal w-[25%] h-full">
                            Geographic Exposure:
                          </td>
                          <td className="w-[37.5%] h-full">
                            <EditableFieldMna
                              data={data?.geoExposureTarget}
                              fieldtype="text"
                              fieldName="geoExposureTarget"
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                          <td className="w-[37.5%] h-full">
                            <EditableFieldMna
                              data={data?.geoExposureAcquirer}
                              fieldtype="text"
                              fieldName="geoExposureAcquirer"
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white   text-sm font-normal w-[25%] h-full">
                            Financial Advisor:
                          </td>
                          <td className="w-[37.5%] h-full">
                            <EditableFieldMna
                              data={data?.financialAdvisorForTarget}
                              fieldtype="text"
                              fieldName="financialAdvisorForTarget"
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                          <td className="w-[37.5%] h-full">
                            <EditableFieldMna
                              data={data?.financialAdvisorForAcquirer}
                              fieldtype="text"
                              fieldName="financialAdvisorForAcquirer"
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white   text-sm font-normal w-[25%] h-full">
                            Legal Counsel:
                          </td>
                          <td className="w-[37.5%] h-full">
                            {" "}
                            <EditableFieldMna
                              data={data?.legalCounselForTarget}
                              fieldtype="text"
                              fieldName="legalCounselForTarget"
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                          <td className="w-[37.5%] h-full">
                            {" "}
                            <EditableFieldMna
                              data={data?.legalCounselForTarget}
                              fieldtype="text"
                              fieldName="legalCounselForAcquirer"
                              fetchMnaData={fetchMnaData}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* CONTACTS */}
              <div className={"flex flex-col w-full gap-4"}>
                <div className="w-full">
                  <div className="border border-[#DCDBEE] rounded-md overflow-visible">
                    <table className="w-full rounded-bl-lg rounded-br-lg bg-table-light dark:bg-table-dark">
                      <caption className="text-left text-[18px] text-title font-semibold pt-4 pb-2 px-4 bg-table-light dark:bg-table-dark">
                        Contacts
                      </caption>
                      <tbody className="">
                        <tr>
                          <td className="px-4   text-[#000817] dark:text-white   text-sm font-medium text-left">
                            1:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            2:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            Info agent:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-4  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            Transfer agent: Broadridge
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* DATES */}
                <div className="w-full ">
                  <div className="border border-[#DCDBEE] rounded-md overflow-visible p-4">
                    <table className="w-full rounded-bl-lg rounded-br-lg bg-table-light dark:bg-table-dark">
                      <caption className="text-left text-[18px] text-title font-semibold pb-2 bg-table-light dark:bg-table-dark">
                        Dates
                      </caption>
                      <tbody className="">
                        <tr className="w-full">
                          <td className="align-top w-1/2">
                            <p className="text-[#696868] dark:text-white text-sm font-normal text-left mt-2">
                              Out Dates:
                            </p>
                          </td>
                          <td className="flex w-full">
                            <div
                              className={`flex ${
                                data.outDates?.length > 1
                                  ? "flex-col"
                                  : "flex-row"
                              } w-full`}
                            >
                              {data.outDates?.map(
                                (item: Item, index: number) => (
                                  <div
                                    key={`out-dates${index}`}
                                    className="flex w-full"
                                  >
                                    <div className="w-1/2 ">
                                      <EditableFieldMna
                                        fieldtype="text"
                                        fieldName="outDates"
                                        data={item?.comment}
                                        fullData={data}
                                        dateIndex={index}
                                        setFetchMnaDetails={setFetchRequired}
                                        setLocalData={setData}
                                        fetchMnaData={fetchMnaData}
                                      />
                                    </div>
                                    <div className="w-1/2">
                                      <EditableFieldMna
                                        data={
                                          item?.dateEvent
                                            ? moment(item.dateEvent).isValid()
                                              ? moment(item.dateEvent).format(
                                                  "MM/DD/YYYY"
                                                )
                                              : ""
                                            : ""
                                        }
                                        fieldtype="date"
                                        fieldName="outDates"
                                        dateIndex={index}
                                        fullData={data}
                                        dateItemValue={item.dateEvent}
                                        setFetchMnaDetails={setFetchRequired}
                                        setLocalData={setData}
                                        fetchMnaData={fetchMnaData}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr className="w-full">
                          <td></td>

                          <td className="flex justify-end w-full">
                            {" "}
                            <button
                              type="button"
                              data-testid="AddNewPlus"
                              onClick={() => {
                                addBrokerClosingDateLocally(
                                  "outDates",
                                  safeId,
                                  setData
                                );
                              }}
                              className={`text-secondary text-sm py-1 px-4 font-bold rounded-md border border-[#C3C1DF] w-auto bg-[#FAFAFE] dark:bg-button-dark dark:text-white ${
                                data.outDates?.length === 0 ? "" : "my-2"
                              }`}
                            >
                              Add new
                            </button>
                          </td>
                        </tr>
                        <tr className="w-full">
                          <td className="align-top w-1/2">
                            <p className="text-[#696868] dark:text-white text-sm font-normal text-left mt-2">
                              Broker Closing Estimate Date:
                            </p>
                          </td>
                          <td className="flex w-full">
                            <div
                              className={`flex ${
                                data.brokerClosingDates?.length > 1
                                  ? "flex-col"
                                  : "flex-row"
                              } w-full`}
                            >
                              {data.brokerClosingDates?.map(
                                (item: Item, index: number) => (
                                  <div
                                    key={`broker-closing${index}`}
                                    className="flex w-full"
                                  >
                                    <div className="w-1/2 ">
                                      <EditableFieldMna
                                        fieldtype="text"
                                        fieldName="brokerClosingDates"
                                        data={item?.comment}
                                        fullData={data}
                                        dateIndex={index}
                                        setFetchMnaDetails={setFetchRequired}
                                        setLocalData={setData}
                                        fetchMnaData={fetchMnaData}
                                      />
                                    </div>
                                    <div className="w-1/2">
                                      <EditableFieldMna
                                        data={
                                          item?.dateEvent
                                            ? moment(item.dateEvent).isValid()
                                              ? moment(item.dateEvent).format(
                                                  "MM/DD/YYYY"
                                                )
                                              : ""
                                            : ""
                                        }
                                        fieldtype="date"
                                        fieldName="brokerClosingDates"
                                        dateIndex={index}
                                        fullData={data}
                                        dateItemValue={item.dateEvent}
                                        setFetchMnaDetails={setFetchRequired}
                                        setLocalData={setData}
                                        fetchMnaData={fetchMnaData}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr className="w-full">
                          <td></td>
                          <td className="flex justify-end w-full">
                            <button
                              type="button"
                              data-testid="AddNewPlus"
                              onClick={() => {
                                addBrokerClosingDateLocally(
                                  "brokerClosingDates",
                                  safeId,
                                  setData
                                );
                              }}
                              className={`text-secondary text-sm py-1 px-4 font-bold rounded-md border border-[#C3C1DF] w-auto bg-[#FAFAFE] dark:bg-button-dark dark:text-white ${
                                data.brokerClosingDates?.length === 0
                                  ? ""
                                  : "my-2"
                              }`}
                            >
                              Add new
                            </button>
                          </td>
                        </tr>
                        <tr className="w-full">
                          <td className="align-top w-1/2">
                            {" "}
                            <p className="text-[#696868] dark:text-white text-sm font-normal text-left mt-2">
                              Financing Date:
                            </p>
                          </td>
                          <td className="flex w-full">
                            <div
                              className={`flex ${
                                data.financingDates?.length > 1
                                  ? "flex-col"
                                  : "flex-row"
                              } w-full`}
                            >
                              {data.financingDates?.map(
                                (item: Item, index: number) => (
                                  <div
                                    key={`financingDatess${index}`}
                                    className="flex w-full"
                                  >
                                    <div className="w-1/2 ">
                                      <EditableFieldMna
                                        fieldtype="text"
                                        fieldName="financingDates"
                                        data={item?.comment}
                                        fullData={data}
                                        dateIndex={index}
                                        setFetchMnaDetails={setFetchRequired}
                                        setLocalData={setData}
                                        fetchMnaData={fetchMnaData}
                                      />
                                    </div>
                                    <div className="w-1/2">
                                      <EditableFieldMna
                                        data={
                                          item?.dateEvent
                                            ? moment(item.dateEvent).isValid()
                                              ? moment(item.dateEvent).format(
                                                  "MM/DD/YYYY"
                                                )
                                              : ""
                                            : ""
                                        }
                                        fieldtype="date"
                                        fieldName="financingDates"
                                        dateIndex={index}
                                        fullData={data}
                                        dateItemValue={item.dateEvent}
                                        setFetchMnaDetails={setFetchRequired}
                                        setLocalData={setData}
                                        fetchMnaData={fetchMnaData}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr className="w-full">
                          <td></td>
                          <td className="flex justify-end w-full">
                            {" "}
                            <button
                              type="button"
                              data-testid="AddNewPlus"
                              onClick={() => {
                                addBrokerClosingDateLocally(
                                  "financingDates",
                                  safeId,
                                  setData
                                );
                              }}
                              className={`text-secondary text-sm py-1 px-4 font-bold rounded-md border border-[#C3C1DF] w-auto bg-[#FAFAFE] dark:bg-button-dark dark:text-white ${
                                data.financingDates?.length === 0 ? "" : "my-2"
                              }`}
                            >
                              Add new
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* MNA BASICS */}
              <div className="md:w-full flex flex-col">
                <div className=" overflow-x-auto">
                  <div className=" min-w-full inline-block align-middle">
                    <div className="border border-[#DCDBEE] rounded-md overflow-hidden p-4">
                      <table className="min-w-full dark:bg-table-dark bg-table-light">
                        <caption className="text-left text-[18px] text-title font-semibold px-4dark:bg-table-dark bg-table-light dark:bg-table-dark">
                          MNA Basics
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col" className="w-1/3"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody className="">
                          <tr>
                            <td className="pt-2 pr-4 text-[#696868] dark:text-white   text-sm font-normal">
                              Date Announced:
                            </td>
                            <td className="pr-4 pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {moment(data?.dateAnnounced).format("MM/DD/YYYY")}
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-2 pr-4 text-[#696868] dark:text-white   text-sm font-normal">
                              Terms:
                            </td>
                            <td className="pr-4 pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.terms}
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-2 pr-4 text-[#696868] dark:text-white   text-sm font-normal">
                              MNA value:
                            </td>
                            <td className="pr-4 pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.size}
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-2 pr-4 text-[#696868] dark:text-white   text-sm font-normal">
                              Premium:
                            </td>
                            <td className="pr-4 pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.premium}
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-2 pr-4 text-[#696868] dark:text-white   text-sm font-normal">
                              Closing Language:
                            </td>
                            <td className="pr-4 pt-2  text-[#000817] dark:text-white  text-sm font-medium text-left truncate max-w-[calc(100vw-80rem)]">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    removeEmptyHTML(data.closingLanguage) || "",
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-2 pr-4 text-[#696868] dark:text-white   text-sm font-normal">
                              Termination Fee:
                            </td>
                            <td className="pr-4 pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.terminationFeeTarget}
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-2 pr-4 text-[#696868] dark:text-white   text-sm font-normal">
                              Reverse Termination Fee:
                            </td>
                            <td className="pr-4 pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.terminationFeeAcquirer}
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-2 pr-4 text-[#696868] dark:text-white text-sm font-normal">
                              Required to Litigate:
                            </td>
                            <td className="pr-4 pt-2 text-[#000817] dark:text-white text-sm font-medium text-left">
                              {data?.requiredToLitigate === true ? "Yes" : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-2 pb-4 pr-4 text-[#696868] dark:text-white   text-sm font-normal">
                              Required to Litigate Comment:
                            </td>
                            <td className="pr-4 pt-2 pb-4  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.requiredToLitigateComment}
                            </td>
                          </tr>

                          <tr>
                            <td className="pr-4 text-[#696868] dark:text-white text-sm font-normal">
                              Appraisal Rights:
                            </td>
                            <td className="pr-4 pt-2 text-[#000817] dark:text-white text-sm font-medium text-left">
                              {data?.appraisalRights || "None"}
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-2 pr-4 text-[#696868] dark:text-white text-sm font-normal">
                              Specific Performance:
                            </td>
                            <td className="pr-4 pt-2 text-[#000817] dark:text-white text-sm font-medium text-left">
                              {data?.specificPerformance || "None"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* WHAT MATTERS / COMPANY DESCRIPTION / BLOBERG INFO */}
            <div className="md:w-1/2 h-full">
              <div className="flex gap-3 mb-4">
                {tabsContent.map((tab, index) => (
                  <button
                    key={`${index}btnn`}
                    className={`min-w-[50px] h-[36px] px-2 ${
                      activeTab === index && "bg-[#E8F2FD] font-semibold"
                    } text-blue-primary border border-blue-primary  text-sm  rounded-md`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.header}
                  </button>
                ))}
              </div>
              <div className="border border-[#E4E3ED] rounded-md h-full bg-table-light dark:bg-table-dark pb-2 ">
                {tabsContent[activeTab].content}
              </div>
              <div className="md:w-1/2 flex flex-col mt-4"></div>
              <div className="overflow-x-auto">
                {/* Holders Top 5 Holders */}
                <div className="min-w-full inline-block">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                    <table className="min-w-full dark:bg-table-dark bg-table-light ">
                      <caption className="text-left text-[18px] font-semibold text-title pt-4 pb-2 px-4 bg-table-light dark:bg-table-dark">
                        Holders Top 5 Holders
                      </caption>
                      <tbody className="">
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-4 text-[#000817] dark:text-white   text-sm font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-4  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-4   text-[#000817] dark:text-white   text-sm font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-4  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-4  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-4  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-4  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-4  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-4 pb-4  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-3.5 pb-4 text-[#D70000] text-sm font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-3.5 pb-4 text-[#007B1B] text-sm font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-4 pb-4 text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {data?.links?.length > 0 && (
                <div className="overflow-x-auto mt-4">
                  <div className="min-w-full inline-block align-middle">
                    {/* LINKS */}
                    <div className="border border-[#DCDBEE] rounded-md overflow-hidden pb-4 bg-table-light dark:bg-table-dark">
                      <table className="w-full rounded-bl-lg rounded-br-lg  bg-table-light dark:bg-table-dark">
                        <caption className="text-left text-[18px] font-semibold pt-4 pb-2 px-4">
                          Links
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col" className="w-1/3"></th>
                            <th scope="col" className="w-2/3"></th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {data?.links?.map(
                            (item: ItemMetaData, index: number) => (
                              <tr key={`links-${index}`}>
                                <td className="px-4 pt-2  text-[#696868] dark:text-white   text-sm font-medium text-left">
                                  {item?.category}
                                </td>
                                <td className="px-4 pt-2  text-[#000817] dark:text-white   text-sm font-medium truncate max-w-[calc(100vw-72rem)]">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.link}
                                  >
                                    {item?.link}
                                  </a>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="overflow-x-auto mt-4">
            <div className="min-w-full inline-block align-middle">
              {/* CONDITIONS */}
              <div className="pt-4 px-4 border border-[#DCDBEE] bg-table-light pb-4 rounded-md ">
                <h4 className="text-xs xs:text-xs text-title sm:text-sm md:text-sm lg:text-lg font-semibold ">
                  Conditions
                </h4>
                <table className="min-w-full dark:bg-table-dark bg-table-light border border-[#DCDBEE] rounded-lg overflow-hidden mt-4 ">
                  <thead className="bg-[#0E7DBB14] ">
                    <tr>
                      <th className="px-4 py-4 text-center">Condition</th>
                      <th className="px-4 py-4 text-start">Name</th>
                      <th className="px-4 py-4 text-start">Commentary</th>
                      <th className="px-4 py-4 text-start">File-By</th>
                      <th className="px-4 py-4 text-start">
                        Expected/Confirmed
                      </th>
                      <th className="px-4 py-4 text-end">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.conditions?.map(
                      (item: IConditions, index: number) => (
                        <tr
                          key={`${index}conditions`}
                          className="border-b border-[#DCDBEE]"
                        >
                          <td className="px-4 py-7 text-start">
                            {item?.category?.name}
                          </td>
                          <td className="px-4 py-7 text-start">
                            {item?.commentary}
                          </td>
                          <td className="px-4 py-7 text-start">
                            {item?.filed === null
                              ? null
                              : moment(
                                  formatAndValidateDate(item?.filed)
                                ).format("MM/DD/YYYY")}
                          </td>
                          <td className="px-4 py-7 text-start">
                            {item?.expectedConfirmed === null
                              ? null
                              : moment(
                                  formatAndValidateDate(item?.expectedConfirmed)
                                ).format("MM/DD/YYYY")}
                          </td>
                          <td className="px-4 py-7 text-end">
                            {item?.statusId}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto mt-4">
            <div className="min-w-full inline-block align-middle bg-table-light dark:bg-table-dark">
              {/* NOTES */}
              <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                <div className="min-w-full rounded-bl-lg rounded-br-lg">
                  <div className="text-left font-semibold  pt-4 pb-2 px-4">
                    <div className="flex items-center justify-between">
                      <div className="text-xs xs:text-xs text-title sm:text-sm md:text-sm lg:text-lg">
                        Notes
                      </div>
                    </div>
                  </div>
                  <div className="p-4">
                    <Table
                      fetchData={fetchMnaNoteList}
                      columns={columnsInstance.NotesListDetail()}
                      additionalDataKey="notes"
                      openModal={openModal}
                    />
                    {}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-4 ">
            <div className="min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-md overflow-hidden bg-table-light dark:bg-table-dark">
                <table className="min-w-full rounded-bl-lg rounded-br-lg">
                  <caption className="text-left text-title text-[18px] font-semibold  pt-4 pb-2 px-4">
                    Attached files
                  </caption>
                  <thead className="">
                    <tr></tr>
                  </thead>
                  <tbody className="">
                    {data?.files?.length > 0 ? (
                      data?.files?.map((item: any, index: number) => (
                        <tr key={`files-${index}`}>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            <button
                              onClick={() =>
                                handleFileDownload(item.filename, item.fileId)
                              }
                            >
                              {item.filename}
                            </button>
                          </td>
                          <td className="px-4 pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {getFileSize(item.size)}
                          </td>
                          <td className="px-4 pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {moment(item?.dateModified).format("MM/DD/YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td
                          colSpan={3}
                          className="text-primary font-bold text-sm p-4"
                        >
                          None
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
      {isModalOpen && content && (
        <div
          className={`fixed top-0 left-0 z-30 flex h-full min-h-screen w-full items-center justify-center px-4 py-5  ${
            isModalOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div
            className="absolute inset-0 transition-opacity bg-[#222222]"
            onClick={closeModal}
          ></div>
          <div className="pointer-events-auto relative flex h-[680px] w-[1440px] flex-col overflow-hidden rounded-md border-none bg-[#EAE5F7] bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div className="flex justify-end p-5">
              <img
                src={close}
                alt="close"
                onClick={() => closeModal()}
                className="rounded-full p-2 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer"
              />
            </div>
            <h2 className="px-[50px] pt-[18px] pb-5 text-[#fff] bg-primary-default dark:bg-black font-semibold text-lg">
              Description
            </h2>
            {/*Modal body*/}
            <div className="relative overflow-y-auto py-4 px-[50px]">
              <ul className="list-disc">
                <li
                  className=" text-[#393C51]  dark:text-white  text-sm font-normal leading-5 capitalize mb-3"
                  dangerouslySetInnerHTML={{
                    __html: content || "",
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MnaDetails;
